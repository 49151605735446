import serviceADX from "@/helpers/py-service";

const state = {
  loading: false,
  meta: {},
  list: [],
};

const mutations = {
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LOADING_LIST(state, value) {
    state.loading = value;
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.loading = true;
    return serviceADX
      .get("bookings/charge-sessions", params)
      .then((data) => {
        store.commit("SET_LIST", data.body.data);
        store.commit("SET_META", data.body);
        return data.body.data;
      })
      .finally(() => (store.state.loading = false));
  },
  updateCustomerList: (store, params) => {
    // store.state.loading = true;
    return serviceADX
      .post("bookings/update-walkin-customer-data", params)
      .then((data) => {
        return data.body.data;
      })
      .finally(() => (store.state.loading = false));
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
};

const csmsChargingSession = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default csmsChargingSession;
