import service from "@/helpers/py-service";
import auth from "./auth/state";

const state = {
  loading: false,
  meta: {},
  list: [
    {
      title: "Trip Summary & Analysis",
      slug: "trips_summary_and_analysis",
      ask: [
        "What was the total distance travelled by all vehicles last week?",
        "Summarize the trips carried out by the top 5 vehicles in terms of mileage in last 3 months",
        "Which vehicle had the longest & shortest trip yesterday?",
        "Which trip had the highest & lowest energy efficiency across last 30 days?",
      ],
      show: ["all", "ev", "ice"],
    },
    {
      title: "Battery Usage",
      slug: "battery_usage",
      ask: [
        "What is the average battery consumption per trip of my fleet?",
        "List the trips where battery consumption was higher than 30%",
        "Which trips had the lowest starting state of charge?",
        "Which motorcycle achieved the highest mileage in KM/KWH?",
      ],
      show: ["all", "ev"],
    },
    {
      title: "Fuel Usage",
      slug: "fuel_usage",
      ask: [
        "What is the average fuel consumption per trip of my fleet?",
        "List the trips where fuel consumption was higher than 30%",
        "Which motorcycle achieved the highest mileage in KM/KWH?",
      ],
      show: ["all", "ice"],
    },
    {
      title: "Speed and Efficiency",
      slug: "speed_and_efficiency",
      ask: [
        "What was the average speed of all vehicles from 6 pm to 9pm yesterday?",
        "Identify trips where the average speed was below/above 30 km/h.",
        "What is the average speed per trip that results in the maximum mileage for my vehicles?",
      ],
      show: ["all", "ev", "ice"],
    },
    {
      title: "Cost Analysis",
      slug: "cost_analysis",
      ask: [
        "What was the total cost for all trips last month? ",
        "Compare the trip costs between Vehicle A and Vehicle.",
        "How much fuel did i save for my entire fleets in the last 30 days?",
      ],
      show: ["all", "ev", "ice"],
    },
    {
      title: "Route & Maps",
      slug: "route_and_maps",
      ask: [
        "Show me the route map for the trip with the highest distance in the last 10 days",
        "Which trips took a route that deviated from the planned path last week?",
        "List the trips that passed through Korangi, Karachi during the last 30 days.",
      ],
      show: ["all", "ev", "ice"],
    },
    {
      title: "Recommendations",
      slug: "recommendations",
      ask: ["Share a recommendation to improve the efficiency of my fleets"],
      show: ["all", "ev", "ice"],
    },
  ],
  selectedFAQ: {},
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_VEHICEL_TRIPS(state, payload) {
    state.list = payload;
  },
  //
  SET_SELECTED_FAQ(state, payload) {
    state.selectedFAQ = payload;
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoading = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_VEHICEL_TRIPS", data.body.data);
        store.commit("SET_META", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },
  chat: (store, params) => {
    store.state.isLoading = true;
    return service
      .post(`ai/ai-insights`, params)
      .then((data) => {
        return data.body.data;
      })
      .finally(() => (store.state.isLoading = false));
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    const user = auth.user;
    if (user.isAdmin) {
      return state.list.filter((r) => r.show.includes("all"));
    } else {
      return state.list.filter((r) => r.show.includes(user.user_tech));
    }
  },
  getSelectedFAQ(state) {
    return state.selectedFAQ;
  },
};

const AIinsights = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default AIinsights;
