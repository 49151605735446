<template>
  <span>
    <v-chip
      v-if="text"
      :class="status"
    >
      {{ formattedText }}
    </v-chip>
    <span
      v-else
      class="status-badge"
      :class="status"
    />
  </span>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default() {
        return "";
      },
    },
    text: {
      type: String,
      default() {
        return "";
      },
    },
  },
  computed: {
    formattedText() {
      return this.text.replace(/_/g, " ").replace(/-/g, " ");
    },
  },
};
</script>
<style lang="sass" scoped>
.v-chip
  text-transform: capitalize
.status-badge
    display: block
    border-radius: 100%
    background: black
    width: 12px
    height: 12px
    margin-right: 0.5rem

.active, .Active , .online
    background: #35af35 !important
.inactive, .Inactive
    background: #f0ae38 !important
.offline, .Offline
    background: #df4f4f !important

.pending_approval
  background: #C3B1E1 !important

.approved
  background: #87CEEB !important

.in_progress, .In-Progress
  background: #FFCC80 !important

.rejected, .cancelled
  background: #fb8787 !important

.completed, .Completed
  background: #20a390 !important

.rescheduled
  background: #c1bdbd !important
</style>
