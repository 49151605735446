<template>
  <div class="">
    <v-menu
      offset-x
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          outlined
          :small="small"
          :large="large"
          :loading="loading"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon> mdi-download-box </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-title>
            <v-img
              v-if="item === 'excel'"
              src="@/assets/icons/download-buttons/xls.png"
              @click="downloadFileOption(item)"
            />
            <v-img
              v-if="item === 'csv'"
              src="@/assets/icons/download-buttons/csv.png"
              @click="downloadFileOption(item)"
            />
            <v-img
              v-if="item === 'pdf'"
              src="@/assets/icons/download-buttons/pdf.png"
              @click="downloadFileOption(item)"
            />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    small: {
      type: Boolean,
      default() {
        return true;
      },
    },
    large: {
      type: Boolean,
      default() {
        return false;
      },
    },
    items: {
      type: Array,
      default() {
        return ["excel", "csv", "pdf"];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    downloadFileOption(item) {
      this.$emit("click", item);
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .v-list-item__title
    cursor: pointer
    margin-bottom: 0.5rem
    img, .v-image
        width: 40px
</style>
