const state = {
  dashboard: {},
  md_makes: {},
  md_models: {},
  vehicles: {},
  vehicle_telematics: {},
  trips: {},
  charge_session: {},
  battery_diagnostics: {},
  remote_on_off: {},
  groups: {},
  //
  vehicle_maintenance_logs: {},
  upcoming_vehicle_maintenance: {},
  vehicle_services_logs: {},
  staff_assignment: {},
  //
  category: {},
  parts: {},
  inventory: {},
  //
  vehicle_comparison: {},
  driver_comparison: {},
  //
  route_listing: {},
  route_planing: {},
  route_comparison: {},
  //
  journey_reports: {},
  daily_mileage_reports: {},
  //
  streams: {},
  plugins: {},
  calculators: {},
  //
  charging_dashboard: {},
  payment_billing: {},
  operations_troubleshooting: {},
  locations: {},
  rates: {},
  charge_stations: {},
  charge_connectors: {},
  bookings: {},
  //
  vehicle_fuel_dashboard: {},
  fuel_dispenser_dashboard: {},
  terminal: {},
  fueltanks: {},
  vehicle_fuel_sessions: {},
  dispensers: {},
  //
  alerts: {},
  notifications: {},
  //
  roles: {},
  users: {},
  tenants_management: {},
  tenant_configurations: {},
  data_sims: {},
  iot_device_providers: {},
  iot_devices: {},
  driving_staff: {},
  oem_customers: {},
  oem_business: {},
  batterries: {},
  support_tickets: {},
  charging_sessions: {},
  ocpp_logs: {},
  fuel_tank: {},
};

const mutations = {
  SET_FILTERS(state, { name, filters }) {
    const fil = { ...filters };
    fil.perPage = fil.itemsPerPage; // for per Page
    delete fil.itemsPerPage;

    // Null key set as Undefined that's y not part of Query Params
    Object.keys(fil).forEach((key) => {
      if (fil[key] == null) {
        fil[key] = undefined;
      }
    });
    // Set as State
    state[name] = fil;
  },
};

const actions = {
  getQueryParams: (store, id) => {
    return store.state[id] || {};
  },
};

const getters = {
  getQueryFilters(state) {
    return (name) => {
      return state[name];
    };
  },
  // vehicleTypesList(state) {
  //   return state.vehicleTypesList;
  // },
};

const redirect = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default redirect;
