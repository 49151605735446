import "@/sass/overrides.sass";
import Vue from "vue";
import admin from "./plugins/admin";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import vuetify from "./plugins/vuetify";
// import moment from "moment";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import i18n from "./i18n";
import VueSweetalert2 from "vue-sweetalert2";
import "./plugins/vue-kanban";
import "./plugins/google-maps";
import "./plugins/vue-app-insights";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import "@/mixins/filters";
// //
// import { ApplicationInsights } from "@microsoft/applicationinsights-web";

// // const customPlugin = new CustomPlugin();
// const appInsights = new ApplicationInsights({
//   config: {
//     instrumentationKey: process.env.VUE_APP_APPINSIGHTS_KEY,
//     // extensions: [customPlugin],
//     /* ...Other Configuration Options... */
//   },
// });
// appInsights.loadAppInsights();

//

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

/* eslint-disable */
Vue.config.productionTip = false;
Vue.use(VueSweetalert2);

new Vue({
  i18n,
  admin,
  store,
  vuetify,
  router,
  render: (h) => h(App),
  mounted() {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(
      window,
      document,
      "clarity",
      "script",
      process.env.VUE_APP_CLARITY_SCRIPT_ID
    );
  },
}).$mount("#app");
