import pyService from "@/helpers/py-service";
const state = {
  loading: false,
  meta: {},
  list: [],
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },

  ADD_LIST(state, payload) {
    state.list.unshift(payload);
  },

  UPDATE_LIST(state, payload) {
    const item = state.list.find((item) => item.id === payload.id);
    Object.assign(item, payload);
  },

  DELETE(state, payload) {
    state.list.splice(state.list.map((o) => o.id).indexOf(payload.id), 1);
  },
};

const actions = {
  list: (store, params) => {
    store.state.loading = true;
    return pyService
      .get(`inventory-and-maintenance/parts`, params)
      .then((res) => {
        if (params.page) {
          store.commit("SET_META", res.body);
          store.commit("SET_LIST", res.body.data);
        } else {
          store.commit("SET_LIST", res.body);
        }
        return res.body;
      })
      .finally(() => (store.state.loading = false));
  },

  add: (store, params) => {
    store.state.loading = true;
    return pyService
      .post(`inventory-and-maintenance/parts`, params)
      .then((res) => {
        store.commit("ADD_LIST", res.body);
        return res.body;
      })
      .finally(() => (store.state.loading = false));
  },

  update: (store, params) => {
    store.state.loading = true;
    return pyService
      .update(`inventory-and-maintenance/parts`, params)
      .then((res) => {
        store.commit("UPDATE_LIST", res.body);
        return res.body;
      })
      .finally(() => (store.state.loading = false));
  },

  destroy: (store, params) => {
    return pyService
      .destroy(`inventory-and-maintenance/parts`, params)
      .then((res) => {
        store.commit("DELETE", params);
        return res;
      });
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },

  getList(state) {
    return state.list;
  },
};

const parts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default parts;
