const Token = process.env.VUE_APP_GOOGLE_MAP_KEY;

import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

import GmapCluster from "vue2-google-maps/dist/components/cluster"; // replace src with dist if you have Babel issues
Vue.component("GmapCluster", GmapCluster);

Vue.use(VueGoogleMaps, {
  load: {
    key: Token,
    libraries: "places", // Add "places" and "visualization" if needed
  },
  installComponents: true, // Ensure all components are globally registered
});
