import service from "@/helpers/py-service";

const state = {
  isLoading: false,
  meta: {},
  list: [],
  listByID: [],
  //
  //

  vehicelMeta: {},
  vehicleList: [],
  clientList: [],
  startLocation: [],
  jobRoutes: {},
  //
};

const mutations = {
  SET_META(state, payload) {
    if (payload) {
      state.meta.page = payload.current_page;
      state.meta.itemsPerPage = payload.per_page;
      state.meta.lastPage = payload.last_page;
      state.meta.totalItems = payload.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_LIST_BY_ID(state, payload) {
    state.listByID = payload;
  },
  //
  //
  //
  SET_VEHICLE_META(state, payload) {
    if (payload) {
      state.vehicelMeta.page = payload.current_page;
      state.vehicelMeta.itemsPerPage = payload.per_page;
      state.vehicelMeta.lastPage = payload.last_page;
      state.vehicelMeta.totalItems = payload.total;
    }
  },
  SET_VEHICLE_LIST(state, payload) {
    state.vehicleList = payload.map((r) => ({
      ...r,
      ...r?.client_assignment[r.client_assignment.length - 1],
      return_to_start: r.return_to_start ? "true" : "false",
    }));
  },
  SET_LOADING(state, value) {
    state.isLoadingMdMakes = value;
  },
  ST(state, payload) {
    state.clientList = payload;
  },
  SET_START_LOCATION(state, payload) {
    state.startLocation = payload;
  },

  SET_JOB_ROUTES(state, payload) {
    state.jobRoutes = payload;
  },
  // DELETE_ROUTE(state, params) {
  //   state.list.splice(state.list.map((o) => o.id).indexOf(params.id), 1);
  // },
  // SET_COMPARE_ROUTE(state, payload) {
  //   state.compareRouteData = payload;
  // },
  SET_LOADING_LIST(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.isLoading = true;
    return service
      .get("routes/vehicle-client-jobs", params)
      .then((data) => {
        store.commit("SET_META", data.body.meta);
        store.commit("SET_LIST", data.body.data);
        return data.body.data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  listByID: (store, params) => {
    store.state.isLoading = true;
    return service
      .get("routes/vehicle-client-jobs", params)
      .then((data) => {
        store.commit("SET_LIST_BY_ID", data.body);
        return data.body;
      })
      .finally(() => (store.state.isLoading = false));
  },
  addJob: (store, params) => {
    store.state.isLoading = true;
    return service
      .post("routes/vehicle-client-jobs", params)
      .then((data) => {
        return data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  destroy: (store, params) => {
    store.state.isLoading = true;
    return service
      .destroy("routes/delete-job", params)
      .then((data) => {
        return data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  //
  //
  //
  vehicleList: (store, params) => {
    store.state.isLoading = true;
    return service
      .get("routes/vehicle-client-assignment", params)
      .then((data) => {
        store.commit("SET_VEHICLE_LIST", data.body);
        return data.body;
      })
      .finally(() => (store.state.isLoading = false));
  },
  addVehicle: (store, params) => {
    store.state.isLoading = true;
    return service
      .post("routes/vehicle-client-assignment", params)
      .then((data) => {
        return data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  clientList: (store, params) => {
    store.state.isLoading = true;
    return service
      .get("routes/tenant-client-list", params)
      .then((data) => {
        store.commit("SET_CLIENT_LIST", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },
  startLocation: (store, params) => {
    store.state.isLoading = true;
    return service
      .get("routes/client-start-location", params)
      .then((data) => {
        store.commit("SET_START_LOCATION", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },

  //
  jobRoutes: (store, params) => {
    store.state.isLoading = true;
    return service
      .get("routes/job-routes", params)
      .then((data) => {
        store.commit("SET_JOB_ROUTES", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },
};

const getters = {
  getLoading(state) {
    return state.isLoading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
  getListByID(state) {
    return state.listByID;
  },
  //
  //
  //
  getVehicleMeta(state) {
    return state.vehicelMeta;
  },
  getVehicleList(state) {
    return state.vehicleList;
  },
  getClientList(state) {
    return state.clientList;
  },
  getStartLocation(state) {
    return state.startLocation;
  },

  getJobRoutes(state) {
    return state.jobRoutes;
  },
};

const routePlaning = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default routePlaning;
